<div class="p-3">
  <div class="row d-flex justify-content-center">
    <div class="row px-4 d-flex justify-content-center">

      <div class="col-md-4 col-sm-6 text-center " *ngIf="error == true">
        <img class="" src="../assets/images/none.svg" alt="">
      </div>

        <div class="col-md-4 col-sm-6 text-center" *ngIf="error == false">
          <img class="" src="../assets/images/check.svg" alt="">
        </div>
      
      <div class="col-md-4 col-sm-6 text-center" *ngIf="error == 'alert'">
        <img class="" src="../assets/images/alert.svg" alt="">
      </div>

    </div>
  </div>
  <div class="modal-body">
    <div class="col-12">
      <h2 class="text-muted fw-normal text-center titles "><strong> {{title}}</strong></h2>
    </div>
      <p class="p-4" style="text-align: justify;">{{message}}</p>
  </div>
  <div class="position-relative text-center">
    <button (click)="modal.close()" class="mt-1 me-2 mb-2 mb-md-0 btn btn-outline-primary style-outline">Cerrar</button>
  </div>
</div>

